import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/layouts/post-layout.js"
import CustomImage from '$components/CustomImage'
import Carousel from '$components/Carousel'
import Gallery from '$components/Layout/Gallery'
import DataProvider from '$components/DataProvider'
import ImageGrid from '$components/Article/ImageGrid'
import { graphql } from 'gatsby'
export const pageQuery = graphql`
    query GatsbyImageUsIran {
        ...ImagesUsIran
    }
`
// Cmment
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>




<MDXTag name="h1" components={components}>{`Introduction`}</MDXTag>
<MDXTag name="p" components={components}>{`One of the vexing issues besetting those active in international relations is the problem of knowledge — how much do we know about a particular situation, country, leader, movement, et cetera? Do we know enough to make good decisions? How confident are we about the information at hand? Who is providing that information? Do we have a grasp of what we do not know? The infamous “black swan” — an unexpected, damaging event — often results from the unknown. Arrogance about knowledge, particularly the belief that one need not know much about unfolding events, is another primary source of failure in global politics. The U.S.-Iran relationship has long been staggered by each party’s ignorance about the other. Missed opportunities rose not merely from bad luck but from knowing too little vital information about the other.`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`» Missed opportunities rose not merely from bad luck but from knowing too little vital information about the other. «`}</MDXTag>
</MDXTag>
<MDXTag name="h1" components={components}>{`Outcome`}</MDXTag>
<ImageGrid
    hint
    numColumns={3}
    images={[
        {
            src: 'main_view',
            description: 'Main timeline view',
        },
        {
            src: 'main_view__entity_hover',
            description: 'Entity hover',
        },
        {
            src: 'main_view__entity_hover_timespan',
            description:
                'Event hover with timespan that ends outsiede the current viewport',
        },
        {
            src: 'search_view',
            description: 'Elastic search view',
        },
        {
            src: 'detail_view_default',
            description: 'Detail view of document',
        },
        {
            src: 'detail_view__tags_out',
            description:
                'Detail view with tags panel expanded & entity group size indicator',
        },
        {
            src: 'detail_view__hover_document',
            description:
                'Event hover with timespan that ends outsiede the current viewport',
        },
        {
            src: 'detail_view__hover_participant',
            description: 'Participant hover',
        },
        {
            src: 'detail_view__timeline_sentiment',
            description: 'Detail view with sentiment analysis',
        },
        {
            src: 'detail_view__timeline_sentiment_compare',
            description: 'Detail view with sentiment analysis and compare tag',
        },
    ]}
/>
<MDXTag name="p" components={components}>{`This project attempts to address this knowledge gap through methods typically used by scholars and policy analysts, such as reading deeply into the history of each country’s foreign policy and relationship with each other. Critical oral history adds to conventional methods by encouraging decision makers to ponder how and why they responded to events and their counterparts. This enables some reflection that typically is not forthcoming in one-on-one interviews. The conferences, briefing documents, and scholars altogether encourage an unusual depth in considering events.`}</MDXTag>
<ImageGrid
    numColumns={3}
    images={[
        {
            src: 'initial_sketches_1',
            description: 'Onboarding',
        },
        {
            src: 'initial_sketches_2',
            description: 'Onboarding',
        },
        {
            src: 'initial_sketches_3',
            description: 'Preferences',
        },
    ]}
/>
<MDXTag name="h1" components={components}>{`Defining the user group`}</MDXTag>
<MDXTag name="p" components={components}>{`We first attempted the creation of a framework, fitting for scholars and the public alike — an application that would guide users through storytelling, introduce them to the topic, one that profoundly relies on the in-depth knowledge of the other sides history and politics. Instead of focusing on both user groups from the beginning, however, we decided to focus on the exploration-driven scholar representation initially. Researchers would then have the possibility to extend the archive by adding documents or creating relationships between existing entities. Mistakes in the data or errors derived from the automated process used to sort the vast amount of data could then also be eradicated.
In our conducted interviews with scholars from the Massachusetts Institute of Technology, it became clear, that a deep understanding of the two countries relation was present. Our initial idea of a storytelling approach, to give a basic overview of critical historical events, was therefore postponed. Instead, we would focus solely on a novel way to explore the archive.`}</MDXTag>
<MDXTag name="h1" components={components}>{`Design Sprint`}</MDXTag>
<MDXTag name="p" components={components}>{`In the following, I will cover a few of the design challenges we faced in the first month of the project. Most of them came up during the initial design sprint week, which generated a lot of early exploratory prototypes.
We started by mapping out any data that the archive would include: participants (organizations, authors, politicians, ...), documents, events and generated personas from the previously conducted interviews.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Main Timeline`}</MDXTag>
<MDXTag name="p" components={components}>{`Since the main timeline would, in the end, play a significant role in introducing the user to the archive, we entirely focused on this element for a few days.`}</MDXTag>
<CustomImage
    style={{ maxWidth: '700px' }}
    imgSrc={'timeline_sketch'}
    description={'Initial sketches of the vertical timeline'}
/>
<MDXTag name="p" components={components}>{`After multiple prototypes, we opted for a vertical timeline. Compressing months with no data to a single label and fine-tuning the spacing, retained the sense of time while keeping the data scannable.
Participants, which would eventually be represented by circles right to the timeline, grow and shrink in size depending on their involvement during the time-period in the viewport.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Circular View`}</MDXTag>
<MDXTag name="p" components={components}>{`In the end, we opted for a circular view to represent documents and events that are related to the current selection of a person, document or event.
Similar to the main timeline, circular shapes represent the actors. Using circle packing, they float around the center which illustrates the active entity.`}</MDXTag>
<CustomImage width={362} imgSrc={'icons_evolution'} />
<MDXTag name="p" components={components}>{`Through multiple user tests, we found triangles and circles to be the best distinguishable shapes at the small form factor.`}</MDXTag>
<MDXTag name="h1" components={components}>{`Use Cases`}</MDXTag>
<MDXTag name="p" components={components}>{`We created the following use cases from interviews conducted with scholars.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Case One – Participant`}</MDXTag>
<MDXTag name="p" components={components}>{`US-Scholar is interested in Martin Indyk’s role in the diplomatic relationship between the US and Iran.
She hopes to find documents that he wrote or where he is mentioned, events that he participated in, or which functions he had in which US governmental unit.`}</MDXTag>
<MDXTag name="p" components={components}>{`A) The main timeline introduces the archive to the scholar. The search bar is visible in a prominent location.`}</MDXTag>
<MDXTag name="p" components={components}>{`B) Searching lazy loads possible results. Common results are preloaded to give the best user experience.`}</MDXTag>
<MDXTag name="p" components={components}>{`C) The detail view shows documents and events the participant is mentioned in. Color coded periods indicate job positions.`}</MDXTag>
<Carousel
    wrapper
    showScrollIndicator={false}
    images={[
        {
            src: 'use_case__01_01',
            description: 'A',
        },
        {
            src: 'use_case__01_02',
            description: 'B',
        },
        {
            src: 'use_case__01_03',
            description: 'C',
        },
    ]}
/>
<MDXTag name="h2" components={components}>{`Case Two – Digging Deeper`}</MDXTag>
<MDXTag name="p" components={components}>{`An Iranian Scholar wants to know what the US involvement was in supplying chemical weapons to Iraq and to what extend that was known by the Iranian side.
He hopes to find original documents, personal accounts, and scholarly articles.`}</MDXTag>
<MDXTag name="p" components={components}>{`Similar to case one, he searched and found, in this case the tag he was looking for.`}</MDXTag>
<MDXTag name="p" components={components}>{`A. The detail view of the Iran tag shows participants, events and documents in which Iran played a role. Activating the tag focuses on documents that involve "chemical weapons".`}</MDXTag>
<MDXTag name="p" components={components}>{`B. Clicking on a document shows the detail view, transscript and meta information about it.`}</MDXTag>
<MDXTag name="p" components={components}>{`C. The sentiment analysis gives a glimpse into how US-Iran relations changed over the given time, preceding certain events.`}</MDXTag>
<Carousel
    wrapper
    showScrollIndicator={false}
    images={[
        {
            src: 'use_case__02_01',
            description: 'A',
        },
        {
            src: 'use_case__02_02',
            description: 'B',
        },
        {
            src: 'use_case__02_03',
            description: 'C',
        },
    ]}
/>
<MDXTag name="p" components={components}>{`[...]`}</MDXTag>
<MDXTag name="h1" components={components}>{`My Role`}</MDXTag>
<MDXTag name="p" components={components}>{`In this project, I was responsible for the concept (circular view, main timeline, minimap, search), UX and the style definitions (style guide). I worked together with the developers to ensure a user-friendly end product.`}</MDXTag>
<CustomImage
    style={{ maxWidth: '700px' }}
    imgSrc={'final_presentation'}
    description={'Final presentation at the MIT in Boston'}
/>
           </MDXTag>
  }
}

export const _frontmatter = {};

  